import { render, staticRenderFns } from "./SellVendorSigninPage.vue?vue&type=template&id=407dffaa&scoped=true"
import script from "./SellVendorSigninPage.vue?vue&type=script&lang=js"
export * from "./SellVendorSigninPage.vue?vue&type=script&lang=js"
import style0 from "./SellVendorSigninPage.vue?vue&type=style&index=0&id=407dffaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407dffaa",
  null
  
)

export default component.exports