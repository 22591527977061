<template>
    <div class="container-fluid1">
      <p>&nbsp;</p>
        <div class="account-details">

          <div class="col-sm-12">
              <div class="contract-header">
                  <div>EDIT PRODUCT </div>
                  <div> <i class="fa fa-eye" @click="GoBack()" style="cursor:pointer;"> List Products</i></div>
              </div>
            <div class="inputs">
              
                <v-autocomplete
                  required
                  v-model="shop_name"
                  label="Business name *"
                  :items="shops"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  required
                  v-model="brand_name"
                  label="Brand *"
                  :items="brands"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  @input="selectCategory"
                  required
                  v-model="category_name"
                  label="Category *"
                  :items="categorys"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                required
                v-model="subcategory_name"
                label="Sub Category *"
                :items="subcategorys"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <div class="inputs">              
                <label for=""> Product name *</label>
                <input type="text" class="form-control inputs-field" placeholder="Enter product name" v-model="product_name" required>
                <input type="hidden" name="" id="" v-model="p_id">
            </div>
            <div class="inputs">              
              <label for=""> Price *</label>
              <input type="text" class="form-control inputs-field" placeholder="Enter product price" v-model="price" required>
            </div>
            <div class="inputs">              
                <label for=""> Product description *</label>
                <quill-editor class="quill-container" v-model="product_description" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product key features </label>
              <quill-editor class="quill-container" v-model="product_keyfeature" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product specification </label>
              <quill-editor class="quill-container" v-model="product_specification" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <div style="display:flex; flex-direction: column; padding: 0.8rem;">
                <center>
                    <img width="50" height="50" :src="img_url + 'products/' + p_img" alt="" style="border-radius: 4px">
                </center>
                <center>
                    <span @click="GoBack()" style="cursor: pointer"><i class="fa fa-arrow-left"></i> Back</span>
                </center>
            </div>
           
            <div class="inputs">
                <button id="submit2" class="btn btn-danger btn-block" :disabled="isProductEnabled"  @click="updateProduct()" v-if="!loading">update</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <p>&nbsp;</p>
            </div>
          </div>
         
        </div>
       
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>

  import 'quill/dist/quill.snow.css';
  import { quillEditor } from 'vue-quill-editor';
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "EditProduct",
    components: {
        quillEditor,
        PageLoader
    },
  
    data(){
      return {
          
          isLoading: false,
          isRightImage: false,
          isLeftImage: false,
          isBackImage: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
          loading1: false,
          loading2: false,
          loading3: false,
          shop_name: '',
          brand_name: '',
          category_name: '',
          subcategory_name: '',
          product_name: '',
          product_description: '',
          product_keyfeature: '',
          product_specification: '',
          price: '',
          response: '',
          subcategory: [],
          categorys:[],
          shops: [],
          brands: [],
          products: [],
          subcategorys:[],
          selectedFile: '',
          selectedFile1: '',
          selectedFile2: '',
          selectedFile3: '',
          addProduct: false,
          viewProduct: true,
          editorOptions: {
            placeholder: 'Write something...',
        },
        isVariations: false,
        productid: '',
        variations: '',
        vprice: '',
        is_submit: false,
        is_submit1: false,
        is_submit2: false,
        is_submit3: false,
        img_url: process.env.VUE_APP_IMAGE_URL,
        header: [
            {
                align: 'start',
                value: 'businessname',
                sortable: false,
                text: 'Vendor',
            },
            { value: 'brand', text: 'Brand' },
            { value: 'category', text: 'Category' },
            { value: 'subcategory', text: 'SubCategory' },
            { value: 'productname', text: 'Product' },
            { value: 'img', text: 'Main Img' },
            { value: 'right', text: 'Right Img' },
            { value: 'left', text: 'Left Img' },
            { value: 'back', text: 'Back Img' },
            { value: 'price', text: 'Price' },
            { value: 'productid', text: 'Variation' },
            { value: 'p_id', text: 'Add image' },
            { value: 'product_id', text: 'Action' },
          ],
        desserts: [],
        user_role: '',
        product_id: '',
        p_id: '',
        p_img: '',
        
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.user_role = localStorage.getItem("user_role");
        this.p_id = this.$route.params.productid
        this.fetchProductsById(this.$route.params.productid)

        if (this.user_role == 1) {
          this.fetchshops()
        }
        else if (this.user_role == 3) {
          this.fetchShopById()
        }

        this.fetchcategorys()
        this.fetchbrands()
    },
  
    created(){
          
    },
  
    computed: {
      isProductEnabled() {
        return (
            this.shop_name === '' ||
            this.brand_name === '' ||
            this.subcategory_name === '' ||
            this.category_name === '' ||
            this.product_name === '' ||
            this.product_description === '' ||
            this.price === ''
        );
      },
      indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
        },     
    },
  
    methods: {

        
        addVariations(productid){
          this.productid = productid
          this.isVariations = true
        },
        async updateProduct() {
            try {

                    this.loading = true

                    this.response = await this.$http.post('/update-product',{
                        product_id : this.p_id,
                        shop_id :this.shop_name,
                        brand_id: this.brand_name,
                        category_id: this.category_name,
                        subcategory_id :this.subcategory_name,
                        product_name :this.product_name,
                        product_description: this.product_description,
                        product_keyfeature: this.product_keyfeature,
                        product_specification: this.product_specification,
                        product_price: this.price,
                        product_image: this.p_img,
                    })

                    this.loading = false

                    if( !this.response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        return;
                    }
                    else{
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
 
                        this.$router.push('/product')
                       
                        return;
                    }
            } catch (err) {
              console.log(err);
            }
        },
        async createVariation() {
            try {
               this.is_submit = true
               const response =  await this.$http.post('/create-variation', {
                product_id: this.productid,
                variation: this.variations,
                price: this.vprice,
                addedby: this.user_id
            })
              this.is_submit = false

            swal.fire({
                icon: 'success',
                title: 'Success',
                width: 450,
                height: 5,
                text: response.data.message,
            })
                 
            } catch (err) {
              console.log(err);
          }
        },
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })
                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchshops() {
            try {
               const response =  await this.$http.get('/fetch-all-shops', {
            })
                response.data.shops.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },
        async fetchShopById() {
            try {
 
               const response =  await this.$http.post('/fetch-shop-byuserid', {
                  userid: this.user_id
                })

                response.data.data.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
                response.data.brands.forEach(el => {
                    this.brands.push({
                        text: el.brand_name,
                        value: el.brandid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async selectCategory(){
            try {

                this.subcategorys = [] // reset list

               const response =  await this.$http.post('/fetch-all-subcategories-byid', {
                category_id: this.category_name
            })
                response.data.categories.forEach(el => {
                    this.subcategorys.push({
                        text: el.subcategory_name,
                        value: el.subcategoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async getSubCategory(categoryid){
            try {

                this.subcategorys = [] // reset list

               const response =  await this.$http.post('/fetch-all-subcategories-byid', {
                category_id: categoryid
            })
                response.data.categories.forEach(el => {
                    this.subcategorys.push({
                        text: el.subcategory_name,
                        value: el.subcategoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchProductsById(product_id) {
            try {

               this.isLoading = true
               const response =  await this.$http.post('/fetch-product-byid', {
                    productid: product_id
                })
                this.isLoading = false
                this.products = response.data.products

                this.shop_name = this.products.shopid,
                this.brand_name = this.products.brand_id
                this.category_name = this.products.category_id
                this.subcategory_name = this.products.subcategory_id
                this.product_name = this.products.product_name
                this.product_description = this.products.product_description
                this.product_keyfeature = this.products.product_keyfeature
                this.product_specification = this.products.product_specification
                this.price = this.products.product_price
                this.p_img = this.products.product_image

                this.getSubCategory(this.category_name)
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchMyProducts(userid) {
            try {
               
               this.isLoading = true
               this.desserts = []
               const response =  await this.$http.post('/fetch-product-byuserid', {
                userid: userid
             })
             this.isLoading = false
                this.products = response.data.products
                this.products.forEach(el => {
                        this.desserts.push({
                          businessname: el.shop_name,
                          brand: el.brand_name,
                          category: el.category_name,
                          subcategory: el.subcategory_name,
                          productname: el.product_name,
                          img: el.product_image,
                          price: el.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                          productid: el.productid,
                          product_id: el.productid,
                          p_id: el.productid,
                          right: el.right_image,
                          left: el.left_image,
                          back: el.back_image,
                        })
                    });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async removeCategory(categoryid){
        let con = confirm("          You're about to remove this category. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/delete-subcategory', {
                subcategory_id: categoryid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchProducts()
                return;

              } catch (err) {
              console.log(err);
              }
          }
        },

        GoBack(){
            this.$router.push('/product')
        }

    }
  
  }
  </script>
   <style lang="scss" scoped>
   @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
   
   .quill-container {
       height: 200px;
       /* Set the desired height here */
   }
   .copy-refid{
       cursor: pointer;
       font-size: 16px;
     }
   .a-text {
       font-size: 11px;
       font-family: Poppins;
   }
   
   label {
       font-size: 12px;
       font-family: Poppins;
       font-weight: normal;
   }
   
   .inputs {
       margin-top: 20px;
       border-radius: 3px;
       font-size: 12px;
   }
   
   .price-fee {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
   
       div {
           margin: 4px;
           width: 100%;
   
       }
   }
   
   .adjust-fee-input {
       // width: 100px;
       text-align: center;
   }
   
   .whopays-fee-input {
       //width: 100% !important;
   }
   
   .shop-detail {
       font-size: 12px;
       font-family: Poppins;
   }
   
   .btn-danger {
       text-transform: uppercase;
       font-size: 13px;
       font-family: Poppins;
       background: #800080c7;
       border: 0px;
       height: 45px;
   }
   
   .text-header {
       padding: 0.5rem;
       font-size: 12px;
       font-family: Poppins;
       background: #ccc;
   }
   
   .contract-header {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       background: #80008048;
       color: #000;
       border-radius: 4px;
       padding: 0.8rem;
       font-size: 14px;
       font-weight: 700;
       font-family: Poppins;
       margin-bottom: 5px;
   }
   
   .welcome {
       font-family: Poppins;
       font-size: 20px;
       font-weight: 700;
       margin-top: 15px;
       color: #170e0fac;
   }
   
   .account-details {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 4px;
       //padding: 0.9rem;
       background: #fff;
       width: 98%;
   }
   
   .account-balance {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: space-between;
       background: #780712;
       color: #fff;
       width: 100%;
       height: 100%;
       border-radius: 10px;
       font-family: Poppins;
       padding: 0.4rem;
       padding-left: 2rem;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   }
   
   .wallet-detail {
       padding-top: 1rem;
       line-height: 1.4px;
       font-size: 15px;
   
       h4 {
           font-weight: 650;
       }
   
       .acct-d {
           line-height: 0.8;
           cursor: pointer;
           text-decoration: underline;
       }
   }
   
   .wallet-icon {
       font-size: 60px
   }
   
   .fund-account {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: space-between;
       background: #076378;
       color: #fff;
       width: 100%;
       height: 100%;
       border-radius: 10px;
       font-family: Poppins;
       padding: 0.4rem;
       padding-left: 2rem;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   }
   
   .inputs-field {
       font-size: 14px;
       font-family: Poppins;
       height: 45px;
   }
   
   .content-center {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border: solid 1px #170e0fac;
       border-radius: 4px;
       padding: 0.5rem;
       margin: 4px;
   }
   
   .box1 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .image-input {
       margin-top: 10px;
   }
   
   .box-icon {
       padding-left: 0.4rem;
       font-size: 35px;
       color: #637807;
   }
   
   .box-details {
       font-size: 14px;
       color: #170e0fac;
   }
   
   .box1:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .box2 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .box2:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .box3 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .box3:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .box4 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .box4:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .box5 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .box5:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .box6 {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: center;
       border-radius: 10px;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
   
       a {
           text-decoration: none;
           color: #000;
       }
   }
   
   .box6:hover {
       transform: scale(1.1);
       transition: transform 0.3s ease;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
       cursor: pointer;
   }
   
   .img {
       border-top-right-radius: 10px;
       border-top-left-radius: 10px;
   }
   
   .box-details {
       padding: 0.4rem;
       line-height: 1.4px;
   
       .title {
           font-family: Poppins;
           font-weight: 550;
           font-size: 10px;
           text-transform: capitalize;
           color: #ff4d63;
       }
   
       .description {
           font-family: Poppins;
           font-size: 12px;
       }
   }
   
   .continue {
       display: flex;
       flex-direction: row;
       justify-content: right;
       padding-right: 0.4rem;
       font-size: 30px;
       color: rgb(237, 138, 108);
       margin-right: 10px;
       margin-top: -10px;
       margin-bottom: 10px;
   }
   
   .modal-mask {
       position: fixed;
       //z-index: 9998;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
       background-color: rgba(0, 0, 0, .5);
       display: table;
       transition: opacity .4s ease;
   
       &>div {
           width: 80%;
           height: auto;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
           background: #fff;
           ;
           backdrop-filter: blur(13px);
           border-radius: 10px;
           box-sizing: border-box;
           font-family: Poppins;
       }
   
       h3 {
           margin-top: 10px;
           font-size: 18px;
           color: #000;
           font-weight: bold;
           font-family: Poppins;
       }
   }
   
   .account-content {
       display: flex;
       flex-direction: column;
       justify-content: center;
       margin-right: 8px;
   }
   
   .account-content-h {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: 50px;
       margin: 10px;
       padding-top: 1.3rem;
       text-transform: uppercase;
       text-align: center;
       //text-decoration: underline;
   }
   
   .add-fund {
       font-size: 12px;
       text-decoration: none !important;
       text-transform: capitalize;
   }
   
   .account-content-d {
       padding-top: 1.3rem;
       font-size: 14px;
       margin-bottom: 10px;
   }
   
   .btn-secondary {
       text-transform: uppercase;
   }
   
   .modal-close {
       color: #000;
       line-height: 50px;
       font-size: 140%;
       position: absolute;
       right: 0;
       text-align: center;
       top: 2;
       width: 70px;
       text-decoration: none;
   
       &:hover {
           color: black;
       }
   }
   
   .modal-close-mobile {
       color: #000;
       line-height: 50px;
       font-size: 140%;
       position: absolute;
       right: 0;
       text-align: center;
       top: 2;
       width: 70px;
       text-decoration: none;
   
       &:hover {
           color: black;
       }
   
       display: none;
   }
   
   a {
       text-decoration: none;
   }
   
   .shop-name {
       //font-weight: 550;
       color: #000;
       font-family: Poppins;
   }
   
   @media screen and (max-width: 768px) {
       .contract-header {
   
           font-size: 12px !important;
   
       }
   
       .counter {
           font-size: 13px;
           font-weight: 500 !important;
       }
   
       .welcome {
           font-family: Poppins;
           font-size: 16px;
           font-weight: 700;
           text-align: left;
           color: #170e0fac;
       }
   
       .content-center {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           padding: 0.9rem;
       }
   
       .image-input {
           margin-top: 120px;
       }
   
       .price-fee {
           display: flex;
           flex-direction: column;
   
           div {
               width: 100%;
           }
       }
       .margin-mobile{
           margin-top: 90px;;
       }
   
       .adjust-fee-input {
           width: 100%;
       }
   
       .account-details {
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           border-radius: 4px;
           width: 100%;
       }
   
       .account-balance {
           margin-bottom: 10px;
       }
   
       .box-details {
           padding: 0.4rem;
           line-height: 1.4px;
   
           .title {
               font-family: Poppins;
               font-weight: 550;
               font-size: 8px;
               text-transform: capitalize;
               color: #ff4d63;
           }
   
           .description {
               font-family: Poppins;
               font-size: 12px;
           }
       }
   
       .inputs {
           font-size: 12px;
       }
   
       .box1 {
           width: 100%;
           height: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
       }
   
       .box2 {
           width: 100%;
           height: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
       }
   
       .box3 {
           width: 100%;
           height: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
       }
   
       .box4 {
           width: 100%;
           height: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
       }
   
       .box5 {
           width: 100%;
           height: 100%;
           border-radius: 10px;
           margin-bottom: 10px;
       }
   
       .continue {
           display: flex;
           flex-direction: row;
           justify-content: right;
           padding-right: 0.4rem;
           font-size: 30px;
           margin-top: -10px;
           color: rgb(237, 138, 108);
       }
   
       .modal-close-mobile {
           display: block;
       }
   
       .modal-close {
           display: none;
       }
   
       .account-content {
           margin: 10px;
           margin-right: 30px;
       }
   
   }
   </style>
   