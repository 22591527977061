<template>
  <div class="container-fluid1">
     <p>&nbsp;</p>
      <p class="welcome">Welcome back, {{ firstname }}</p>
       <div class="col-lg-12 col-sm-12 pt-6 dashboard-page">
            <div class="col-lg-6 col-sm-6">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Inventory/Orders</strong>
              <div class="dashboard-inner">
                  <div class="active-c">
                      <div class="activeo">
                        <div>
                          <span class="icon pl-4"><i class="fa fa-database" aria-hidden="true"></i></span>
                        </div>
                        <div class="active-cc">
                          <span class="name">Total Inventory</span>
                        </div>
                      </div>
                      
                      <div class="active-cc">
                        <span class="amount" v-if="!is_products">{{ total_products }} </span>
                        <span class="amount" v-if="is_products"> </span>
                      </div>
                  </div>
                  <div class="active-c mt-4">
                    <div class="activeo">
                      <div>
                        <span class="icon pl-4"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>
                      </div>
                      <div class="active-cc">
                        <span class="name">Total Orders</span>
                        
                      </div>
                    </div>
                    <div class="active-cc">
                      <span class="amount" v-if="!order_loading">{{ total_orders }} </span>
                      <span class="amount" v-if="order_loading">{{ total_orders }} </span>
                    </div>
                  </div>
              </div>
            </div>
            
            <div class="col-lg-6 col-sm-6">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Vendor Data</strong>
              <div class="dashboard-inner">
                  <div class="wallet-board">
                      <div class="wallet-account">
                        <div class="account-note" v-if="user_role == 1">
                          <div class="text-justify text-white">Statistics:</div> 
                        </div>
                        <div class="account-note" v-if="user_role == 3">
                          <div class="text-justify text-white">Complete this section below:</div> 
                        </div>
                        <div class="account-number" v-if="user_role == 3">
                            <p> <router-link to="/shop"><i class="fa fa-home" aria-hidden="true"></i> Business Info <span class="complete" v-if="valid_shops != ''"><i class="fa fa-check"></i></span> <span class="complete1" v-else-if="valid_shops == ''"><i class="fa fa-times text-danger" aria-hidden="true"></i></span></router-link></p>
                            <p> <router-link to="/shipping"> <i class="fa fa-car" aria-hidden="true"></i> Shipping Info <span class="complete" v-if="shipping != ''"><i class="fa fa-check"></i></span> <span class="complete1" v-else-if="shipping == ''"><i class="fa fa-times text-danger" aria-hidden="true"></i></span></router-link></p>
                            <p><router-link to="/bank"><i class="fa fa-bank"></i> Payment <span class="complete" v-if="user_bank_detail != ''"><i class="fa fa-check"></i></span> <span class="complete1" v-else-if="user_bank_detail == ''"><i class="fa fa-times text-danger" aria-hidden="true"></i></span></router-link></p>
                            <p><router-link to="/profile"><i class="fa fa-user" aria-hidden="true"></i> My Profile <span class="complete"><i class="fa fa-check"></i></span></router-link></p>
                        </div>
                        <div class="account-number" v-if="user_role == 1">
                          <p><i class="fa fa-users"></i> Vendor(s): <span class="complete" v-if="shop_length != ''">{{ shop_length}}</span> <span class="complete1" v-else-if="shop_length == ''">-</span></p>
                          <p><i class="fa fa-users"></i> Buyer(s): <span class="complete" v-if="buyer_length != ''">{{ buyer_length}}</span> <span class="complete1" v-else-if="buyer_length == ''">-</span></p>
                          <p><router-link to="/profile"><i class="fa fa-user" aria-hidden="true"></i> My Profile <span class="complete"><i class="fa fa-check"></i></span></router-link></p>
                      </div>
                      </div>
                  </div>
              </div>
            </div>
       </div>
       <div class="col-lg-12 col-sm-12 dashboard-page">
        <div class="col-lg-12 col-sm-12">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Transaction</strong>
          <div class="dashboard-inner1">
            <div class="active-c-history">
              <div class="table-responsive table-bordered table-striped table-sm">
                <v-data-table
                  :headers="header2"
                  :items="desserts2"
                  density="compact"
                  dense
                  class="custom-table"
                >
                <template v-slot:item.product_name="{ item }">
                  <div style="font-size: 10px">
                      <span>{{ item.product_name }}</span>
                  </div>
                </template>
                <template v-slot:item.vendor="{ item }">
                  <div style="font-size: 10px">
                      <span>{{ item.vendor }}</span>
                  </div>
                </template>
                <template v-slot:item.date="{ item }">
                  <div style="font-size: 10px">
                      <span>{{ new Date(item.date).toDateString() }}</span>
                  </div>
                </template>
                <template v-slot:item.order_no="{ item }">
                  <div style="font-size: 10px">
                      <span>{{ item.order_no }}</span>
                  </div>
                </template>
                <template v-slot:item.total_amount="{ item }">
                  <div style="font-size: 10px">
                      <span>&#8358;{{ item.total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</span>
                  </div>
                </template>
                <template v-slot:item.installment="{ item }">
                  <div style="font-size: 10px">
                      <span>&#8358;{{ item.installment }}</span>
                  </div>
                </template>
                <template v-slot:item.qty="{ item }">
                  <div style="display: flex; flex-direction: row; align-items: center; width: 15px;">
                  <span style="font-size: 10px">{{ item.qty }}</span>
                </div>
                </template>
                <template v-slot:item.img="{ item }" style="width: 15px;">
                  <img width="40" height="40" :src="img_url + item.img" alt="" >
                </template>
                <template v-slot:item.duration1="{ item }" >
                  <span style="font-size: 10px;" v-html="item.duration1"></span>
                </template>
                <template v-slot:item.paid="{ item }" >
                  <div v-if="item.payment_confirm == 1">
                    <div class="progress" style="height: 15px;font-size: 9px;width: 100px; ">
                      <div class="progress-bar bg-success" role="progressbar" :style="{ width: item.payment_count/item.duration * 100 +'%' }" aria-valuenow="10" aria-valuemin="0" :aria-valuemax="item.duration">
                        Completed
                      </div>
                    </div>
                  </div>
                  <div v-if="item.payment_confirm == 0" class="progress" style="height: 15px;font-size: 9px; width: 100px;">
                    <div class="progress-bar bg-danger" role="progressbar" :style="{ width: item.payment_count/item.duration * 100 +'%' }" aria-valuenow="10" aria-valuemin="0" :aria-valuemax="item.duration">
                      {{ Math.round((item.payment_count/item.duration) * 100) +'%' }}
                    </div>
                  </div>
                </template>
                <template v-slot:item.buyer="{ item }">
                  <div class="payment-schedule1 text-secondary" style="font-size: 10px" >{{ item.buyer}}</div>
                </template>
               
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
       
       </div>
        <p>&nbsp;</p>
      <page-loader :is-loading="isLoading"></page-loader>
  </div>
</template>
<script>
 //import swal from 'sweetalert2';
 import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "HomeVew",
    components: {
      PageLoader
    },
  
    data(){
      return {
          search: '',
          loading: false,
          isLoading: false,
          isLoading1: false,
          tab1_view: true,
          tab2_view: false,
          isWithdrawal: false,
          isReferralWithdrawal: false,
          tab1: true,
          tab2: false,
          phone_number: '',
          email_address: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          isAccountDetail: false,
          isAddfund: false,
          current_plan: '',
          current_plan_name: '',
          weekly_income: '',
          planid: '',
          expiry_date: '',
          expiry_status: '',
          referral_code: '',
          total_orders: '',
          referral_earnings: '',
          order_loading: false,
          referral_loading: false,
          active_loading: false,
          referral_count_loading: false,
          payment_history: false,
          payments:[],
          cummulative_earning: '',
          referral_count: 0,
          totalTasks: '',
          isWithdrawal_time: '',
          withdrawal_list: [],
          amount: '',
          active_referrals: [],
          referral_withd: '',
          img_url: process.env.VUE_APP_IMAGE_URL + '/products/',
          header: [
            {
              align: 'start',
              value: 'package',
              sortable: false,
              text: 'Package',
            },
            { value: 'amount', text: 'Amount' },
            { value: 'paymentref', text: 'Payment Ref' },
            { value: 'date', text: 'Date' },
          ],
          desserts: [],
  
          header2: [
            {
            align: 'start',
            value: 'vendor',
            sortable: false,
            text: 'Vendor',
          },
          {
            align: 'start',
            value: 'product_name',
            sortable: false,
            text: 'Product',
          },
          { value: 'img', text: 'Img' },
          { value: 'order_no', text: 'Order no.' },
          { value: 'date', text: 'Date' },
          { value: 'qty', text: 'Qty' },
          { value: 'total_amount', text: 'Amount' },
          { value: 'installment', text: 'Installment' },
          { value: 'duration1', text: 'Duration' },
          { value: 'paid', text: '%Paid' },
          { value: 'buyer', text: 'Customer' },
        ],
          desserts2: [],
          withdrawalCount: 0,
          user_bank_detail: '',
          wallet_balance: 0,
          total_products: '',
          is_products: false,
          active_orders: '',
          ordered_items: '',
          valid_shops: '',
          shipping: '',
          shop_length: '',
          shop_items:[],
          buyer_length: ''
        };
    },
  
    beforeMount(){
      this.user_id = localStorage.getItem("userid");
      //this.checkExpiryDate(this.user_id)
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.referral_code = localStorage.getItem("referral_code");
        this.user_role = localStorage.getItem("user_role");

        if (this.user_role == 1) {
          this.fetchAdminProducts()
          this.fetchAdminOrders()
          this.fetchAllAdminActiveOrders()
          this.fetchAllShops()
          this.fetchBuyers()
        }
        else if (this.user_role == 3) {
          this.fetchProducts(this.user_id);
          this.totalOrders(this.user_id);
          this.fetchActiveOrders(this.user_id);
          this.paymentHistory();
          this.fetchAllActiveOrders()
          this.validateShop(this.user_id)
          this.validateBank(this.user_id)
          this.fetchShippingByUserId()
        }
       
       
    },
  
    created(){
          
    },
  
    computed: {
      cummulativeEarnings(){
        return this.total_orders + this.referral_earnings;
      },
      indexSn() {
        return this.desserts.map((d, index) => ({
            ...d,
            sn: index + 1
        }))
      },
      isSubmitButtonDisabled() {
          return (
            this.amount === ''
          );
      }
    },
  
    methods: {
  
      async fetchShippingByUserId() {
              try {
                this.isLoading = true
                this.desserts = []
                 const response =  await this.$http.post('/fetch-shipping-byuserid', {
                    userid: this.user_id
                  })
                this.isLoading = false
                this.shipping = response.data.data
                 
              } catch (err) {
                console.log(err);
            }
      },

      async validateShop(userid){
        try {
            const response =  await this.$http.post('/fetch-shop-byuserid', {
              userid: userid,
            })
            this.valid_shops = response.data.data
            return;
            
          } catch (err) {
          console.log(err);
          }
      },

      async validateBank(userid) {
              try {
  
                 const response =  await this.$http.post('/fetch-user-bank', {
                    userid: userid,
                  })
  
                  this.user_bank_detail = response.data.bank
                      
              } catch (err) {
                console.log(err);
            }
      },

      async fetchProducts(userid){
          try {
              this.is_products = true
              const response =  await this.$http.post('/total-products', {
                user_id: userid,
              })
              this.is_products = false
              this.total_products = response.data.products;
              return;
              
            } catch (err) {
            console.log(err);
            }
      },

      async fetchAdminProducts() {
            try {
                this.is_products = true
               const response =  await this.$http.get('/fetch-all-products', {
             })
                this.is_products = false
                this.total_products = response.data.products.length;
                    
            } catch (err) {
              console.log(err);
          }
      },

        async fetchAdminOrders(){
          try {
              this.order_loading = true
                  const response =  await this.$http.get('/fetch-orders', {
              })
              this.order_loading = false
              this.total_orders = response.data.orders.length;
                            
            } catch (err) {
            console.log(err);
            }
      },
  
      async totalOrders(userid){
          try {
              this.order_loading = true
              const response =  await this.$http.post('/total-orders', {
                user_id: userid,
              })
              this.order_loading = false
  
              this.total_orders = response.data.products;
              return;
              
            } catch (err) {
            console.log(err);
            }
      },
  
      async fetchActiveOrders(userid) {
            try {
  
                this.active_loading = true
                const response =  await this.$http.post('/count-active-orders', {
                  user_id: userid
                })
                this.active_loading = false
                this.active_orders = response.data.products                    
            } catch (err) {
              console.log(err);
          }   
      },
  
      async fetchAllActiveOrders() {
            try {
  
                this.desserts2 = []
                this.isLoading1 = true;
                const response = await this.$http.post('/fetch-active-orders', {
                   user_id: this.user_id
                })
                
                let withd_status = '';
                this.ordered_items = response.data.orders;
                this.cart_length =  this.ordered_items.length
                this.ordered_items.forEach(el => {
  
                if(el.installment == 1){
                  withd_status = el.duration + "weeks";
                }else if(el.installment == 2){
                  withd_status = el.duration + "months";
                }
  
                this.desserts2.push({
                  userid: el.userid,
                  payment_count: el.payment_count,
                  payment_confirm: el.payment_confirm,
                  vendor: el.shop_name,
                  product_name: el.product_name,
                  img: el.product_image,
                  date: new Date(el.created_at).toLocaleString(),
                  qty: el.qty,
                  order_no: el.orderref,
                  total_amount: el.unit_price * el.qty,
                  installment: el.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                  duration1: withd_status,
                  duration: el.duration,
                  paid: el.payment_confirm,
                  buyer: el.fullname,
                  action: el.order_status,
                })
              }) 
              this.isLoading1 = false;
            } catch (err) {
                console.log(err);
            }
      },

      async fetchAllAdminActiveOrders() {
            try {
  
                this.desserts2 = []
                this.isLoading1 = true;
                const response = await this.$http.get('/fetch-admin-active-orders', {
                })
                
                let withd_status = '';
                this.ordered_items = response.data.orders;
                this.cart_length =  this.ordered_items.length
                this.ordered_items.forEach(el => {
  
                if(el.installment == 1){
                  withd_status = el.duration + "weeks";
                }else if(el.installment == 2){
                  withd_status = el.duration + "months";
                }
  
                this.desserts2.push({
                  userid: el.userid,
                  payment_count: el.payment_count,
                  payment_confirm: el.payment_confirm,
                  vendor: el.shop_name,
                  product_name: el.product_name,
                  img: el.product_image,
                  date: new Date(el.created_at).toLocaleString(),
                  qty: el.qty,
                  order_no: el.orderref,
                  total_amount: el.unit_price * el.qty,
                  installment: el.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                  duration1: withd_status,
                  duration: el.duration,
                  paid: el.payment_confirm,
                  buyer: el.fullname,
                  action: el.order_status,
                })
              }) 
              this.isLoading1 = false;
            } catch (err) {
                console.log(err);
            }
      },

      async fetchAllShops() {
            try {
  
                this.desserts2 = []
                this.isLoading1 = true;
                const response = await this.$http.get('/fetch-all-shops', {
                })
                
                let withd_status = '';
                this.shop_items = response.data.shops;
                this.shop_length =  this.shop_items.length
                this.shop_items.forEach(el => {
  
                if(el.installment == 1){
                  withd_status = el.duration + "weeks";
                }else if(el.installment == 2){
                  withd_status = el.duration + "months";
                }
  
                this.desserts2.push({
                  userid: el.userid,
                  payment_count: el.payment_count,
                  payment_confirm: el.payment_confirm,
                  vendor: el.shop_name,
                  product_name: el.product_name,
                  img: el.product_image,
                  date: new Date(el.created_at).toLocaleString(),
                  qty: el.qty,
                  order_no: el.orderref,
                  total_amount: el.unit_price * el.qty,
                  installment: el.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                  duration1: withd_status,
                  duration: el.duration,
                  paid: el.payment_confirm,
                  buyer: el.fullname,
                  action: el.order_status,
                })
              }) 
              this.isLoading1 = false;
            } catch (err) {
                console.log(err);
            }
      },
  
      async paymentHistory() {
        try {
              this.payment_history = true
              const response =  await this.$http.post('/payment-history', {
                  userid: this.user_id
              })
              this.payment_history = false
              this.payments = response.data.payments;
              this.payments.forEach(el => {
              this.desserts.push({
                package: el.plan_name,
                amount: 'NGN'+el.amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                paymentref: el.payment_ref,
                date: new Date(el.created_at).toDateString()
              })
            }); 
          } catch (err) {
            console.log(err);
        }
      },

      async fetchBuyers() {
              try {
                    const response =  await this.$http.get('/fetch-all-users_byrole', {
                    })
                    this.buyer_length = response.data.users.length

              } catch (err) {
                console.log(err);
            }
        }, 
     
      viewTab1(){
        this.tab1_view = true
        this.tab2_view = false
      },
      viewTab2(){
        this.tab1_view = false
        this.tab2_view = true
      },
      copyToClipboard() {
        var copyText = document.getElementById("myInput");
        navigator.clipboard.writeText(copyText.value).then(function() {
            //alert("Copied the text: " + copyText.value);
            alert("Referral link copied!");
        }).catch(function(err) {
            console.error('Failed to copy: ', err);
        });
      },
      marketplace(){
        this.$router.push("/myorders")
      }
    }
  }
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.welcome{
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  padding: 0px 20px;
  text-align: left;
  color: #170e0fac;
}
 .dashboard-page{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #cccccc32;
    padding: 0.6rem;
    font-family: Poppins;
    strong{
      font-weight: normal;
      font-family: Poppins;
    }
 }
 .wallet-board{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    background: #807b7b33;
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
    height: 100%;
 }
 .wallet-account{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #800080c7;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
 }
 .account-note{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-content: center;
  border-bottom: solid 1px #ccc;
  padding: 0.6rem;

    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 13px;
      line-height: 1.2;
    }

 }
 .account-number{
    padding: 0.6rem;
  
  p{
    font-family: Poppins;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    a{
      text-decoration: underline;
      color: #fff;
    }
  }
 }

 .wallet-balance{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: 60%;
    height: 100%;
    background-image: linear-gradient(-90deg, #fff, #cccccc56, #fff);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    
    h4{
      font-family: Poppins;
      font-weight: 600;
      font-size: 20px;
      color: white;
      text-shadow: 2px 2px 4px #000000;
    }
 }
 .withdrawal{
    font-family: Poppins;
    font-weight: normal;
    font-size: 12px;
    color: #2979FF;
    border: solid 1px;
    padding: 0.2rem;
    border-radius: 4px;
 }
 .dashboard-inner{
  overflow-y: auto;
  height: 255px;
  width: 100%;
  border-radius: 15px;
  padding: 1.2rem;
  margin: 10px;
  background: #fff;
 }
 .dashboard-inner1{
  overflow-y: auto;
  height: auto;
  width: 100%;
  border-radius: 15px;
  padding: 1.2rem;
  margin: 10px;
  background: #fff;
 }
 .active-c{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #cccccc32;
  border-radius: 15px;
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); 
  height: 100px;
  width: 100%;

  .icon{
    font-size: 44px;
    color: #800080c7;
  }
 }
 .active-c-history{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #cccccc32;
  border-radius: 4px;
  height: auto;
  width: 100%;
  padding: 0px;
  margin-top: 4px;

  .icon2{
    font-size: 20px;
  }
 }
 .activeo{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
 }
 .active-cc{
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  font-family: Poppins;

  .name{
    color: #800080c7;
    font-weight: 500;
    font-family: Poppins;
  }

  .location{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 10px;
  }

  .amount{
    color: #800080c7;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
  }

  .cost-price{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 12px;
  }

  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
  }

 }
 .active-cc-history{
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    span{
      color: #800080c7;
      font-style: italic;
    }
  }
  .amount{
    color: #800080c7;
    font-weight: normal;
    font-family: Poppins;
    font-size: 16px;
  }
  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }

  .amount-w{
    color: #ff2929;
    font-weight: normal;
    font-family: Poppins;
    font-size: 11px;
  }
  .date-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }

  .name-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    span{
      color: #800080c7;
      font-style: italic;
    }
  }

 }

 .active-cc-user{
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 12px;
    color: #800080c7;
    font-weight: 700;
  }
  .email{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    color: #807b7b;
  }

 }
 .active-c-user{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #cccccc32;
  border-radius: 4px;
  height: auto;
  width: 100%;
  padding: 0px;
  margin-top: 4px;
  .icon3{
    font-size: 40px;
  }
 }


@media screen and (max-width: 768px) {
  .welcome{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    padding: 0px 15px;
    text-align: left;
    color: #170e0fac;
  }

  .dashboard-page{
    display: flex;
    flex-direction: column;
    strong{
      margin-top: 10px;
    }
 }
 .dashboard-inner{
  margin: 0px;
  margin-top: 10px;
 }

 .active-c{

  .icon{
    font-size: 40px;
    color: #800080c7;
  }
 }

 .active-cc{
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  font-family: Poppins;

  .name{
    color: #800080c7;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
  }

  .location{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 10px;
    font-size: 10px;
  }

  .amount{
    color: #800080c7;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
  }

  .cost-price{
    color: #807b7b;
    font-weight: 600;
    font-family: Poppins;
    margin-top: 10px;
    font-size: 10px;
  }

  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
  }

 }
 .wallet-board{
  padding: 0.5rem;
}

.account-note{
  border-bottom: solid 1px #ccc;
  padding: 0.4rem;

    div{
      font-family: Poppins;
      font-weight: normal;
      font-size: 13px;
      line-height: 1;
    }

 }
 .account-number{
    padding: 0.4rem;
  
  p{
    font-family: Poppins;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    color: #fff;
  }
 }
 .wallet-balance{
    h4{
      font-weight: normal;
      font-size: 14px;
      text-shadow: 2px 2px 4px #000000;
    }
 }
 .withdrawal{
    font-family: Poppins;
    font-weight: normal;
    font-size: 10px;
    color: #800080c7;
    border: solid 1px;
    padding: 0.2rem;
 }

 .active-c-history{
  padding: 0px;
  margin-top: 10px;
  .icon2{
    font-size: 15px;
  }
 }

 .active-cc-history{
  padding: 0.2rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
    span{
      color: #800080c7;
      font-style: italic;
    }
  }
  .amount{
    color: #800080c7;
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }
  .date{
    font-weight: normal;
    font-family: Poppins;
    font-size: 8px;
  }

  .amount-w{
    color: #ff2929;
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
  }
  .date-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 8px;
  }

  .name-w{
    font-weight: normal;
    font-family: Poppins;
    font-size: 9px;
    span{
      color: #800080c7;
      font-style: italic;
    }
  }

 }
 .active-cc-user{
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  font-family: Poppins;

  .name{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    color: #800080c7;
    font-weight: 700;
  }
  .email{
    font-weight: normal;
    font-family: Poppins;
    font-size: 10px;
    color: #807b7b;
  }

 }
 .active-c-user{
  padding: 0px;
  margin-top: 4px;
  .icon3{
    font-size: 23px;
  }
 }
  
}

</style>