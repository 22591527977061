<template>
    <div class="layout-container">
        <div class="main-container">
            <div class="sidebar">
                <div class="logo-title">
                    Daily Champ
                </div>
                <div class="menu-sidebar-top">main menu</div>
                <div class="menu-sidebar">
                    <router-link class="sub-menu" to="/home"><i class="fa fa-home"></i>&nbsp;Home </router-link>
                    <router-link class="sub-menu" to="/product"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Product </router-link>
                    <router-link class="sub-menu" to="/vendor/orders"><i class="fa fa-history" aria-hidden="true"></i>&nbsp;Order </router-link>
                    <router-link class="sub-menu" to="/vendor/settlements"><i class="fa fa-money" aria-hidden="true"></i>&nbsp;Settlement  </router-link>
                    <router-link v-if="user_role == 1" class="sub-menu" to="/purchase/history"><i class="fa fa-file" aria-hidden="true"></i>&nbsp;Purchase History  </router-link>
                    <router-link v-if="user_role == 1" class="sub-menu" to="/vendor/lists"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;Vendors  </router-link>
                    <router-link v-if="user_role == 1" class="sub-menu" to="/buyer/lists"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;Buyers  </router-link>
                    <div class="sub-menu" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                </div>
            </div>
    
            <div class="content">
                <div class="top-header">
                    <div class="notifications search-bar">
                        <div class="inputs-field"><i class="fa fa-headphones"></i> For any assistance, call 07069500520 or email: support@dailychamp.ng</div>
                    </div>
                    <div class="message-notifications">
                        <span class="notifications envelope"><i class="fa fa-envelope"></i></span>
                        <span class="notifications bell"><i class="fa fa-bell"></i></span>
                    </div> 
                    <div class="notifications menu-bar">
                        <i class="fa fa-bars"></i>
                        <div class="submenu-bar">
                            <div><img src="../assets/logo_.png" alt="" style="width: 30%; padding: 0.8rem"></div>
                            <router-link class="sub-menu1" to="/home"><i class="fa fa-home"></i>&nbsp;Home </router-link>
                            <router-link class="sub-menu1" to="/product"><i class="fa fa-tasks" aria-hidden="true"></i>&nbsp;Product </router-link>
                            <router-link class="sub-menu1" to="/vendor/orders"><i class="fa fa-history" aria-hidden="true"></i>&nbsp;Order </router-link>
                            <router-link class="sub-menu1" to="/vendor/settlements"><i class="fa fa-money" aria-hidden="true"></i>&nbsp;Settlement </router-link>
                            <router-link v-if="user_role == 1" class="sub-menu1" to="/purchase/history"><i class="fa fa-file" aria-hidden="true"></i>&nbsp;Purchase History  </router-link>
                            <router-link v-if="user_role == 1" class="sub-menu1" to="/vendor/lists"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;Vendors  </router-link>
                            <router-link v-if="user_role == 1" class="sub-menu1" to="/buyer/lists"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;Buyers  </router-link>
                            <div class="sub-menu1" style="cursor: pointer" @click="vendorLogout()">&nbsp;<i class="fa fa-sign-out"></i> Logout</div>
                            
                        </div>
                    </div>                   
                </div>
                <router-view/>
            </div>
        </div>
    
        <!-- <div class="footer">
            Privacy Policy | Disclaimer
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br>
            &copy;{{ new Date().getFullYear() }} {{ appTitle }} All rights reserved.
        </div> -->
    </div>
    
</template>
<script>
import inactivityMixin from '../mixins/inactivityMixin';
export default {

    name: "Dashboard",
    mixins: [inactivityMixin],
    components: {

    },

    data(){
        return {
            appTitle: 'Daily Champ',
            //appTitle: process.env.VUE_APP_COMPANY,
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
            pageTitle: '',
        };
    },

    created() {
    this.$root.$on('title', (title) => {
      this.pageTitle = title;
        });
    },
    beforeDestroy() {
        this.$root.$off('title');
    },
      
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");

        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }
    },
   
    computed: {
        
    },
    methods:{
        vendorLogout(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
            localStorage.removeItem("user_role");
            localStorage.removeItem("userid");
            localStorage.removeItem('cart_id');
            this.$router.push("/login")
        }
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.search-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.top-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #8000800a;
    padding: 3px 30px 10px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.038);
}
.message-notifications{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}
.notifications{
    margin: 10px;
}
.envelope{
    color: #ccc;
    cursor: pointer;
}
.bell{
    color: #800080c7;
    cursor: pointer;
}
.inputs-field{
    font-size: 12px;
    font-family: Poppins;
    height: auto;
    padding: 0.3rem;
    width: 500px;
    border-radius: 0px;
    position: relative;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #cccccc2b;
    border: solid 0px;
}
.search-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    background: #cccccc2b;
    color: #ccc;
    border-radius: 0px;
    font-family: Poppins;
    font-size: 18px;
    text-transform: capitalize;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.logo-title {
    padding: 30px 30px 20px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 800;
    color: #fff;
    flex: 1;
    border-bottom: solid 1px #ccc;
}

.menu-bar {
    flex: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu-bar .submenu-bar {
    display: none;
}

.menu-bar .fa-bars:hover + .submenu-bar,
.menu-bar .submenu-bar:hover {
    display: block;
    position: absolute;
    background: #800080;
}

.user-info {
    flex: 1;
    text-align: right;
}

.dropdown .dropbtn {
    background-color: #800080c7;
    color: #000;
    padding: 12px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}

.main-container {
    display: flex;
    flex: 1;
}
.menu-sidebar-top {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 50px 30px 0;
}
.menu-sidebar {
    padding: 20px 10px 0;
    width: 220px;
    
}

.sidebar {
    background: #800080c7;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.sidebar .sub-menu {
    display: block;
    padding: 0.5rem;
    color: #fff;
    font-family: Poppins;
    font-size: 11px;
    font-weight: normal;
    text-decoration: none;
    margin-left: 10px;
}

.sidebar .sub-menu:hover {
    background: #80008052;
    color: #fff;
    border-radius: 4px;
}

.content {
    flex: 1;
    //margin: 10px;
    //background: #0000ff1d;
    width: 100%;
}

.footer {
    text-align: center;
    padding: 10px;
    background: #fff;
    box-shadow: 0 -1px 2px rgba(0,0,0,0.1);
}

 .menu-bar{
    display:none;
 }

 .logo-title{
    display:block;
 }

/* Style The Dropdown Button */
.dropbtn {
   // background-color: #4CAF50;
    color: white;
    //padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1 !important;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Poppins;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #800080c7;
    color: #fff;
    font-family: Poppins;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #fff !important;
        text-decoration: none;
        font-size: 15px !important;
        font-weight: normal !important;

        img{
            width: 60px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}

@media screen and (max-width: 768px) { 

    .top-header {
        padding: 10px 10px 5px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.038);
        width: 100%;
    }
    .inputs-field{
        width: 100%;
    }
    .message-notifications{
        display: none;
    }
    .notifications{
        padding: 0px;
    }
    .envelope:hover{
        cursor: pointer;
    }
    .bell:hover{
        cursor: pointer;
    }

    .sidebar{
        display: none;
    }
    .logo-title{
        display: none;
    }
    .menu-bar{
        display:block;
        //justify-content: right;
        .fa-bars{
            font-size: 30px;
            cursor: pointer;
            color: #800080;
            //text-align: right;
        }
        .submenu-bar{
            background: #800080;
        }
     }
     .menu-bar:hover{
        
            .submenu-bar{
                display: flex;
                flex-direction: column;
                top: 0;
                right: 0;
                font-family: Poppins;
                font-size: 13px;
                border-right: solid 1px rgba(0, 0, 0, 0.3);
                position: fixed;
                z-index: 9998;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                width: 60%;
                height: 100%;
                            
                a{
                    text-decoration: none;
                }
            }
            .sub-menu1{
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                border-bottom: solid 1px #ccc;
                text-transform: capitalize;
                cursor: pointer;
                padding: 0.4rem;
                width: 100%;
                color: #fff;
                text-decoration: none;
            }
     }
     .sub-menu1:hover{
        background: #fff;
        color: #800080 !important;
     }
     
    .menu{
        display: none;       
    }
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 12px;
        display: flex;
    }
    .navbar-bgcolor{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #800080;
        color: #fff;
        font-family: Poppins;
       box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    
        a{
            color: #fff !important;
            text-decoration: none;
            font-size: 13px !important;
            font-weight: normal !important;
    
            img{
                width: 50px;
            }
        }
        a:hover{
            color: #fff;
        }
    }
}

</style>