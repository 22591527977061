import Vue from 'vue'
import VueRouter from 'vue-router'
import ReferralSignup from '../views/ReferralSignup.vue'
import Dashboard from '../views/DashboardPage.vue'
import HomeView from '../views/HomeView.vue'
import BankPage from '../views/BankPage.vue'
import ShopPage from '../views/ShopPage.vue'
import ShippingInfo from '../views/ShippingInfo.vue'
import EditShipping from '../views/EditShipping.vue'
import EditShop from '../views/EditShop.vue'
import ProductPage from '../views/ProductPage.vue'
import EditProduct from '../views/EditProduct.vue'
import VendorList from '../views/VendorList.vue'
import BuyerList from '../views/BuyerList.vue'
import PurchaseHistory from '../views/PurchaseHistory.vue'
import ProfilePage from '../views/ProfilePage.vue'

// FORGOT PASSWORD
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/CreatePassword.vue'

// SELL VENDOR PAGE
import SellVendorSigninPage from '../views/SellVendorSigninPage.vue'
import SellVendorSignupPage from '../views/SellVendorSignupPage.vue'

// BUYERS
import MyOrders from '../views/MyOrders.vue'

// MY ACCOUNTS
import MyCommissions from '../views/MyCommissions.vue'

Vue.use(VueRouter)

// parse jwt token
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function beforeRouteEnter (to, from, next) {
  try{
  if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
    const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        //deleteTokenFromLocalStorage();
        next("/login");
    }
    next();
  } else {
    next("/login");
  }
  }catch (err) {
    console.log(err);
  }
}

const routes = [
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },  
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },  
  {
    path: '/ref-register/:referral',
    name: 'ReferralSignup',
    component: ReferralSignup
  },
  {
    path: '/login',
    name: 'SellVendorSigninPage',
    component: SellVendorSigninPage
  },
    {
    path: '/register',
    name: 'SellVendorSignupPage',
    component: SellVendorSignupPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/home',
        name: 'HomeView',
        beforeEnter : beforeRouteEnter,
        component: HomeView,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/profile',
        name: 'ProfilePage',
        beforeEnter : beforeRouteEnter,
        component: ProfilePage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shop',
        name: 'ShopPage',
        beforeEnter : beforeRouteEnter,
        component: ShopPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shipping',
        name: 'ShippingInfo',
        beforeEnter : beforeRouteEnter,
        component: ShippingInfo,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shipping/edit/:shippingid',
        name: 'EditShipping',
        beforeEnter : beforeRouteEnter,
        component: EditShipping,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/shop/edit/:shopid',
        name: 'EditShop',
        beforeEnter : beforeRouteEnter,
        component: EditShop,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/product',
        name: 'ProductPage',
        beforeEnter : beforeRouteEnter,
        component: ProductPage,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/bank',
        name: 'BankPage',
        beforeEnter : beforeRouteEnter,
        component: BankPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/edit-product/:productid',
        name: 'EditProduct',
        beforeEnter : beforeRouteEnter,
        component: EditProduct,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/vendor/orders',
        name: 'MyOrders',
        beforeEnter : beforeRouteEnter,
        component: MyOrders,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/vendor/settlements',
        name: 'MyCommissions',
        beforeEnter : beforeRouteEnter,
        component: MyCommissions,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/vendor/lists',
        name: 'VendorList',
        beforeEnter : beforeRouteEnter,
        component: VendorList,
        meta: {
          secure:true,
        }
      },  

      {
        path: '/buyer/lists',
        name: 'BuyerList',
        beforeEnter : beforeRouteEnter,
        component: BuyerList,
        meta: {
          secure:true,
        }
      },  

      {
        path: '/purchase/history',
        name: 'PurchaseHistory',
        beforeEnter : beforeRouteEnter,
        component: PurchaseHistory,
        meta: {
          secure:true,
        }
      },  
      
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
