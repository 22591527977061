<template>
    <div class="container-fluid1">
        <p>&nbsp;</p>
        <div class="account-details table table-responsive">
            <div class="col-sm-12 table table-responsive">
                <div class="contract-header">
                    <div>Buyer Lists<span class="badge badge-primary">{{ contractQty }}</span></div>
                    <div> </div>
                </div>
    
                <div class="table table-responsive table-sm">
                  <v-data-table
                  :headers="header"
                  :items="desserts"
                  dense
                  class="table-striped table-bordered table-responsive"
                >
                <template v-slot:item.businessname="{ item }">
                  <div class="a-text">{{ item.businessname }}</div>
                </template> 
                <template v-slot:item.fullname="{ item }">
                  <div class="a-text">{{ item.fullname }}</div>
                </template>   
                <template v-slot:item.desc="{ item }">
                    <div class="a-text">{{ item.desc }}</div>
                </template>  
                <template v-slot:item.email="{ item }">
                    <div class="a-text">{{ item.email }}</div>
                </template>  
                <template v-slot:item.phone="{ item }">
                    <div class="a-text">{{ item.phone }}</div>
                </template> 
                <template v-slot:item.bank="{ item }">
                    <div class="a-text">{{ item.bank }}</div>
                </template> 
                <template v-slot:item.nin_bvn="{ item }">
                    <div class="a-text">{{ item.nin_bvn }}</div>
                </template> 
                <template v-slot:item.address="{ item }">
                    <div class="a-text">{{ item.address }}</div>
                </template> 
                <template v-slot:item.vphone="{ item }">
                    <div class="a-text text-success" v-if="item.vphone == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vphone == 0">Not-verified</div>
                </template> 
                <template v-slot:item.vemail="{ item }">
                    <div class="a-text text-success" v-if="item.vemail == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vemail == 0">Not-verified</div>
                </template> 
                <template v-slot:item.vaddress="{ item }">
                    <div class="a-text text-success" v-if="item.vaddress == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vaddress == 0">Not-verified</div>
                </template> 
                <template v-slot:item.vbank="{ item }">
                    <div class="a-text text-success" v-if="item.vbank == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vbank == 0">Not-verified</div>
                </template> 
                <template v-slot:item.vbvn="{ item }">
                    <div class="a-text text-success" v-if="item.vbvn == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vbvn == 0">Not-verified</div>
                </template> 
                <template v-slot:item.vnin="{ item }">
                    <div class="a-text text-success" v-if="item.vnin == 1">verified</div>
                    <div class="a-text text-danger" v-if="item.vnin == 0">Not-verified</div>
                </template>  
                <template v-slot:item.nin_image="{ item }">
                    <span v-if="item.nin_image == null" class="a-text">N/A </span>
                    <span v-else>
                      <img width="40" height="40" :src="img_url + 'nins/' + item.nin_image" alt="" style="border-radius: 4px">
                    </span>
                  </template>    
                <template v-slot:item.created_at="{ item }">
                    <div class="a-text">{{ new Date(item.created_at).toDateString() }}</div>
                </template>
                </v-data-table>
                </div>
            </div>
        </div>
       
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
      
    <script>
    
    import 'quill/dist/quill.snow.css';
    //import { quillEditor } from 'vue-quill-editor';
    //import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
      
      name: "ProductPage",
      components: {
          //quillEditor,
          PageLoader
      },
    
      data(){
        return {
            
            isLoading: false,
            isRightImage: false,
            isLeftImage: false,
            isBackImage: false,
            user_id: '',
            firstname: '',
            lastname: '',
            bank_name:'',
            account_name:'',
            account_number: '',
            
            loading: false,
            loading1: false,
            loading2: false,
            loading3: false,
            shop_name: '',
            brand_name: '',
            category_name: '',
            subcategory_name: '',
            product_name: '',
            product_description: '',
            product_keyfeature: '',
            product_specification: '',
            price: '',
            response: '',
            subcategory: [],
            categorys:[],
            shops: [],
            brands: [],
            user_details: [],
            subcategorys:[],
            selectedFile: '',
            selectedFile1: '',
            selectedFile2: '',
            selectedFile3: '',
            addProduct: false,
            viewProduct: true,
            editorOptions: {
              placeholder: 'Write something...',
          },
          isVariations: false,
          productid: '',
          variations: '',
          vprice: '',
          is_submit: false,
          is_submit1: false,
          is_submit2: false,
          is_submit3: false,
          img_url: process.env.VUE_APP_IMAGE_URL,
          header: [
              { value: 'fullname', text: 'Fullname' },
              { value: 'username', text: 'Username' },
              { value: 'email', text: 'Email' },
              { value: 'phone', text: 'Phone' },
              { value: 'address', text: 'Delivery address' },
              { value: 'bank', text: 'Bank' },
              { value: 'vphone', text: 'Phone verify' },
              { value: 'vemail', text: 'Email verify' },
              { value: 'vaddress', text: 'Address verify' },
              { value: 'vbank', text: 'Bank verify' },
              { value: 'vbvn', text: 'BVN verify' },
              { value: 'vnin', text: 'Nin verify' },
              { value: 'nin_bvn', text: 'Nin/Bvn' },
              { value: 'nin_image', text: 'Nin image' },
              { value: 'created_at', text: 'Date created' },
            ],
          desserts: [],
          user_role: '',
          product_id: '',
          productQty: '',
          
        };
      },
    
      beforeMount(){
       
      },
    
      mounted(){
  
          this.firstname = localStorage.getItem("firstname");
          this.lastname = localStorage.getItem("lastname");
          this.phone_number = localStorage.getItem("phone");
          this.email_address = localStorage.getItem("email");
          this.user_id = localStorage.getItem("userid");
    
          this.bank_name = localStorage.getItem("bank_name");
          this.account_name = localStorage.getItem("account_name");
          this.account_number = localStorage.getItem("account_number");
          this.user_role = localStorage.getItem("user_role");

          this.fetchSubadmin()
           
      },
    
      created(){
            
      },
    
      computed: {

        indexSn() {
              return this.desserts.map((d, index) => ({
                  ...d,
                  sn: index + 1
              }))
        },
              
      },
    
      methods: {
                 
          async fetchSubadmin() {
              try {
                this.isLoading = true
                 this.desserts = []
                 const response =  await this.$http.get('/fetch-all-users_byrole', {
               })
                  this.isLoading = false
                  this.user_details = response.data.users
                  this.productQty = this.user_details.length
                  this.user_details.forEach(el => {
                          this.desserts.push({
                            fullname: el.fullname,
                            username: el.username,
                            email: el.email_address,
                            phone: el.phone_number,
                            address: el.address_type +', '+ el.address +', ' + el.city + ' ' +el.state,
                            bank: el.bank_name + ' ' + el.account_name +' '+ el.account_number,
                            vphone: el.verify_phone,
                            vemail: el.verify_email,
                            vaddress: el.verify_address,
                            vbank: el.verify_bank,
                            vbvn: el.verify_bvn,
                            vnin: el.verify_nin,
                            nin_bvn: 'NIN: ' + el.nin + ', BVN: ' + el.bvn,
                            nin_image: el.nin_image,
                            created_at: el.created_at,
                          })
                      });
                      
              } catch (err) {
                console.log(err);
            }
          }, 
  
      }
    
    }
    </script>
      
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    
    .quill-container {
        height: 200px;
        /* Set the desired height here */
    }
    .copy-refid{
        cursor: pointer;
        font-size: 16px;
      }
    .a-text {
        font-size: 11px;
        font-family: Poppins;
    }
    
    label {
        font-size: 12px;
        font-family: Poppins;
        font-weight: normal;
    }
    
    .inputs {
        margin-top: 20px;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .price-fee {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    
        div {
            margin: 4px;
            width: 100%;
    
        }
    }
    
    .adjust-fee-input {
        // width: 100px;
        text-align: center;
    }
    
    .whopays-fee-input {
        //width: 100% !important;
    }
    
    .shop-detail {
        font-size: 12px;
        font-family: Poppins;
    }
    
    .btn-danger {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Poppins;
        background: #800080c7;
        border: 0px;
        height: 45px;
    }
    
    .text-header {
        padding: 0.5rem;
        font-size: 12px;
        font-family: Poppins;
        background: #ccc;
    }
    
    .contract-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #80008048;
        color: #000;
        border-radius: 4px;
        padding: 0.8rem;
        font-size: 14px;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: 5px;
    }
    
    .welcome {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
        color: #170e0fac;
    }
    
    .account-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        //padding: 0.9rem;
        background: #fff;
        width: 98%;
    }
    
    .account-balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #780712;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .wallet-detail {
        padding-top: 1rem;
        line-height: 1.4px;
        font-size: 15px;
    
        h4 {
            font-weight: 650;
        }
    
        .acct-d {
            line-height: 0.8;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    
    .wallet-icon {
        font-size: 60px
    }
    
    .fund-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #076378;
        color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        font-family: Poppins;
        padding: 0.4rem;
        padding-left: 2rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .inputs-field {
        font-size: 14px;
        font-family: Poppins;
        height: 45px;
    }
    
    .content-center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 1px #170e0fac;
        border-radius: 4px;
        padding: 0.5rem;
        margin: 4px;
    }
    
    .box1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .image-input {
        margin-top: 10px;
    }
    
    .box-icon {
        padding-left: 0.4rem;
        font-size: 35px;
        color: #637807;
    }
    
    .box-details {
        font-size: 14px;
        color: #170e0fac;
    }
    
    .box1:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box3:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box4:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box5 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box5:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .box6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Poppins;
    
        a {
            text-decoration: none;
            color: #000;
        }
    }
    
    .box6:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
        cursor: pointer;
    }
    
    .img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    
    .box-details {
        padding: 0.4rem;
        line-height: 1.4px;
    
        .title {
            font-family: Poppins;
            font-weight: 550;
            font-size: 10px;
            text-transform: capitalize;
            color: #ff4d63;
        }
    
        .description {
            font-family: Poppins;
            font-size: 12px;
        }
    }
    
    .continue {
        display: flex;
        flex-direction: row;
        justify-content: right;
        padding-right: 0.4rem;
        font-size: 30px;
        color: rgb(237, 138, 108);
        margin-right: 10px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            ;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Poppins;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Poppins;
        }
    }
    
    .account-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
    }
    
    .account-content-h {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        margin: 10px;
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        //text-decoration: underline;
    }
    
    .add-fund {
        font-size: 12px;
        text-decoration: none !important;
        text-transform: capitalize;
    }
    
    .account-content-d {
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .btn-secondary {
        text-transform: uppercase;
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .shop-name {
        //font-weight: 550;
        color: #000;
        font-family: Poppins;
    }
    
    @media screen and (max-width: 768px) {
        .contract-header {
    
            font-size: 12px !important;
    
        }
    
        .counter {
            font-size: 13px;
            font-weight: 500 !important;
        }
    
        .welcome {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            color: #170e0fac;
        }
    
        .content-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0.9rem;
        }
    
        .image-input {
            margin-top: 120px;
        }
    
        .price-fee {
            display: flex;
            flex-direction: column;
    
            div {
                width: 100%;
            }
        }
        .margin-mobile{
            margin-top: 90px;;
        }
    
        .adjust-fee-input {
            width: 100%;
        }
    
        .account-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 100%;
        }
    
        .account-balance {
            margin-bottom: 10px;
        }
    
        .box-details {
            padding: 0.4rem;
            line-height: 1.4px;
    
            .title {
                font-family: Poppins;
                font-weight: 550;
                font-size: 8px;
                text-transform: capitalize;
                color: #ff4d63;
            }
    
            .description {
                font-family: Poppins;
                font-size: 12px;
            }
        }
    
        .inputs {
            font-size: 12px;
        }
    
        .box1 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box2 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box3 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box4 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .box5 {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-bottom: 10px;
        }
    
        .continue {
            display: flex;
            flex-direction: row;
            justify-content: right;
            padding-right: 0.4rem;
            font-size: 30px;
            margin-top: -10px;
            color: rgb(237, 138, 108);
        }
    
        .modal-close-mobile {
            display: block;
        }
    
        .modal-close {
            display: none;
        }
    
        .account-content {
            margin: 10px;
            margin-right: 30px;
        }
    
    }
    </style>
    